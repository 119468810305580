import React, {useEffect} from "react"
import {
  CommonBanner,
  Container,
  Link,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { LinkedInIcon } from "../components/core/icons";
import { TwitterIcon } from "../components/core/icons";
import { PageLayout } from "../components/site/layout/page-layout";

const ContactUsPage = ({ location }) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "2449407",
          formId: "464b5b54-f0f9-4482-a33d-8b47e33a6ec1",
          target: '#offer-contact-form'
        })
      }
    });
  }, []);

  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Contact Us" />

      <Section>
        <Container className="py-8">
          <div className="m-0 mt-8 text-4xl font-light tracking-tight text-center font-heading text-primary-500">
            Have a Question? Send Us a Message
          </div>
        </Container>

        <Container className="bg-yellow-100 bg-opacity-75 rounded-xl container-sm">
          <div className="p-4">
            <p className="m-0">
              For trial or paid Approov account holders, please direct technical
              queries to{' '}
              <Link className="" href="mailto:support@approov.io">
                support@approov.io
              </Link>
              .
            </p>
            <p className="m-0 mt-2">
              If you are a paid account holder and have an urgent issue, you can
              obtain additional contact information using the{' '}
              <code className="">approov support</code> CLI command.
            </p>
          </div>
        </Container>

        <Container className="py-8">
          <div className="flex flex-col md:flex-row md:items-center">
            <div id="offer-contact-form" className="p-4 flex-auto"></div>
            <div className="mt-4 md:mt-0 md:ml-16 md:flex-shrink-0">
              <h3 className="mt-4 mb-2">US Headquarters</h3>
              <p className="mt-2 mb-0">165 University Avenue</p>
              <p className="mt-0 mb-0">Suite 200</p>
              <p className="mt-0 mb-0">Palo Alto, CA 94301 USA</p>
              <p className="mt-0 mb-2"> +1 (650) 322-5300</p>
              <h3 className="mt-4 mb-2">UK Headquarters</h3>
              <p className="mt-2 mb-0">181 The Pleasance</p>
              <p className="mt-0 mb-0">Edinburgh, Midlothian</p>
              <p className="mt-0 mb-0">EH8 9RU, United Kingdom</p>
              <p className="mt-0 mb-2">+44 0131 655 1500</p>
              <div className="flex mt-4">
                <LinkButton
                  className="ml-2 button-secondary button-sm bg-[#86b146]"
                  href="https://www.linkedin.com/company/criticalblue/"
                  rel="nofollow"
                >
                  <LinkedInIcon className="w-5 h-5" />
                </LinkButton>
                <LinkButton
                  className="ml-2 button-secondary button-sm bg-[#86b146]"
                  href="https://www.twitter.com/@approov_io"
                  rel="nofollow"
                >
                  <TwitterIcon className="w-5 h-5" />
                </LinkButton>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </PageLayout>
  )
}

export default ContactUsPage
